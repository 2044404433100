const currencies = {
  uah: 'UAH',
  eur: 'EUR',
}

export const currencySigns = {
  [currencies.uah]: '₴',
  [currencies.eur]: '€',
}

export const currencyUpSigns = {
  Uah: '₴',
  Eur: '€',
}

export const currencyUpHumanNames = {
  Uah: 'грн',
  Eur: '€',
}

export default currencies
